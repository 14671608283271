"use client";
import { useState, useCallback, useEffect } from "react";
import Dropzone, { FileRejection, DropEvent } from "react-dropzone";
import client from "@/client";
import toast from "react-hot-toast";
import axios from "axios";
import { z } from "zod";
import { PortableText } from "@portabletext/react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface Job {
  name: string;
  field: string;
  location: string;
}

interface OpenPositionProps {
  data: {
    sectionId: string;
    title: string;
    jobs: Job[];
  };
}

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required."),
  lastName: z.string().min(1, "Last name is required."),
  email: z.string().min(1, "Email is required.").email("A valid email is required."),
  phoneNumber: z
    .string()
    .min(1, "Phone number is required.")
    .refine((val) => /^\+?[0-9]{10,15}$/.test(val), "A valid phone number is required."),
  file: z.instanceof(File).refine((val) => val !== null, {
    message: "Please upload your CV.",
  }),
});

const OpenPosition = ({ data, pageData }: { data: any; pageData: any }) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentJob, setCurrentJob] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [agreedToTerms1, setAgreedToTerms1] = useState<boolean>(false);
  const [agreedToTerms2, setAgreedToTerms2] = useState<boolean>(false);
  const [agreedToTerms3, setAgreedToTerms3] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [termsErrors, setTermsErrors] = useState<Record<string, boolean>>({});

  const handleClick = (jobName: string) => {
    setIsOpen(true);
    setCurrentJob(jobName);
  };

  const validateFields = (): boolean => {
    try {
      formSchema.parse({ firstName, lastName, email, phoneNumber, file });
      setValidationErrors({});
      return true;
    } catch (e) {
      if (e instanceof z.ZodError) {
        const errors: Record<string, string> = {};
        e.errors.forEach((err) => {
          if (err.path[0]) {
            errors[err.path[0] as string] = err.message;
          }
        });
        setValidationErrors(errors);
      }
      return false;
    }
  };

  const validateTerms = (): boolean => {
    const errors: Record<string, boolean> = {};

    if (!agreedToTerms1) errors.agreedToTerms1 = true;
    if (!agreedToTerms2) errors.agreedToTerms2 = true;
    if (!agreedToTerms3) errors.agreedToTerms3 = true;

    setTermsErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const clearValidationError = (field: string) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[field];
      return updatedErrors;
    });
  };

  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const isValidFields = validateFields();
    const isValidTerms = validateTerms();
    if (!isValidFields || !isValidTerms) {
      toast.error("Please complete all fields and agreements.");
      return;
    }

    setLoading(true);
    try {
      if (!file) return;
      const fileAsset = await client.assets.upload("file", file, {
        contentType: file.type,
        filename: file.name,
      });
      const fileUrl = `https://cdn.sanity.io/files/pkwqd9sd/production/${fileAsset.assetId}.pdf`;

      const doc = {
        _id: Math.random().toString(36).substr(2, 8),
        _type: "jobApplication",
        title: currentJob,
        firstName,
        lastName,
        email,
        phoneNumber,
        pdfFile: {
          _type: "file",
          asset: {
            _ref: fileAsset._id,
          },
        },
      };

      await client.createIfNotExists(doc);

      try {
        const response = await axios.post("/api/send-job-email", {
          fileUrl,
          fileName: file.name,
          title: currentJob,
          firstName,
          lastName,
          email,
          phoneNumber,
        });
        if (response.status !== 200) {
          throw new Error("Email sending failed");
        }
      } catch (emailError) {
        console.error("Error sending email:", emailError);
        toast.error("Error sending email.");
      }
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("Submission Error 🎉");
    } finally {
      setLoading(false);
      setIsOpen(false);
      setFile(null);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setAgreedToTerms1(false);
      setAgreedToTerms2(false);
      setAgreedToTerms3(false);
      setValidationErrors({});
      setTermsErrors({});
      toast.success("Application Sent Successfully 🎉");
    }
  };

  const onDrop = useCallback((acceptedFiles: File[], _fileRejections: FileRejection[], _event: DropEvent) => {
    setFile(acceptedFiles[0]);
    setValidationErrors((prev) => ({ ...prev, file: "" }));
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setFile(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAgreedToTerms1(false);
    setAgreedToTerms2(false);
    setAgreedToTerms3(false);
    setValidationErrors({});
    setTermsErrors({});
    setValidationErrors({});
    setTermsErrors({});
  };
  return (
    <section id={data?.sectionId} className="position bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
      <h1 id="careers" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
        {data?.title}
      </h1>
      {isOpen && (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="sm:flex min-h-full justify-center p-4 text-center items-center p-0 px-8">
              <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full md:w-7/12">
                <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
                  <h2 className="text-xl font-normal">Job Application</h2>
                  <button className="curesor-pointer" onClick={handleClose}>
                    ✖
                  </button>
                </div>
                <div className="bg-[#0f192991] pb-4 pt-5 sm:p-6 sm:pb-4 p-5 sm:p-0">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <input
                        type="text"
                        placeholder={pageData[0].formFields.field1}
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          clearValidationError("firstName");
                        }}
                        className={`w-full pl-6 px-4 py-3 rounded-full border ${validationErrors.firstName ? "border-red-500" : "border-gray-300"} outline-none text-white text-lg bg-gray-800 placeholder-gray-400 focus:border-white`}
                      />
                      {validationErrors.firstName && <p className="text-red-500 text-sm mt-1">{validationErrors.firstName}</p>}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder={pageData[0].formFields.field2}
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          clearValidationError("lastName");
                        }}
                        className={`w-full pl-6 px-4 py-3 rounded-full border ${validationErrors.lastName ? "border-red-500" : "border-gray-300"} outline-none text-white text-lg bg-gray-800 placeholder-gray-400 focus:border-white`}
                      />
                      {validationErrors.lastName && <p className="text-red-500 text-sm mt-1">{validationErrors.lastName}</p>}
                    </div>
                    <div>
                      <input
                        type="email"
                        placeholder={pageData[0].formFields.field3}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          clearValidationError("email");
                        }}
                        className={`w-full pl-6 px-4 py-3 rounded-full border ${validationErrors.email ? "border-red-500" : "border-gray-300"} outline-none text-white text-lg bg-gray-800 placeholder-gray-400 focus:border-white`}
                      />
                      {validationErrors.email && <p className="text-red-500 text-sm mt-1">{validationErrors.email}</p>}
                    </div>
                    <div>
                      <div className="w-full relative inputField">
                        <PhoneInput
                          defaultCountry={pageData[0].formFields.field4.toString()}
                          value={phoneNumber}
                          onChange={(value) => {
                            setPhoneNumber(value || "");
                            clearValidationError("phoneNumber");
                          }}
                          // placeholder={pageData[0].formFields.field4}
                          international
                          className={`w-full pl-6 pr-4 py-3 text-lg text-white bg-gray-800 rounded-full outline-none border ${validationErrors.phoneNumber ? "border-red-500" : "border-gray-300"} focus:border-white`}
                        />
                      </div>
                      {validationErrors.phoneNumber && <p className="text-red-500 text-sm mt-1">{validationErrors.phoneNumber}</p>}
                    </div>
                  </div>
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={`border-2 border-dashed ${validationErrors.file ? "border-red-500" : "border-gray-500"} p-6 rounded-lg bg-gray-700 text-center mb-4`}>
                        <input {...getInputProps()} />
                        <p className="text-gray-400">
                          Drag & Drop or <span className="text-blue-500 cursor-pointer">Choose file</span> to upload
                        </p>
                        <p className="text-gray-400 mt-2">Maximum Size: 25MB</p>
                        {file && <p className="text-white mt-4">Selected file: {file.name}</p>}
                        {validationErrors.file && <p className="text-red-500 text-sm mt-1">{validationErrors.file}</p>}
                      </div>
                    )}
                  </Dropzone>
                  <div className="mb-4">
                    <h4 className="text-white text-base mb-2">{pageData[0].agreements.list[0].heading}</h4>
                    <label className={`block text-sm ${termsErrors.agreedToTerms1 ? "text-red-500" : "text-white"}`}>
                      <input
                        type="checkbox"
                        checked={agreedToTerms1}
                        onChange={() => {
                          setAgreedToTerms1(!agreedToTerms1);
                          setTermsErrors((prev) => ({ ...prev, agreedToTerms1: false }));
                        }}
                        className="mr-2"
                      />
                      {pageData[0].agreements.list[0].agreement}
                    </label>
                  </div>
                  <div className="mb-4">
                    <h4 className="text-white text-base mb-2">{pageData[0].agreements.list[1].heading}</h4>
                    <label className={`block text-sm ${termsErrors.agreedToTerms2 ? "text-red-500" : "text-white"}`}>
                      <input
                        type="checkbox"
                        checked={agreedToTerms2}
                        onChange={() => {
                          setAgreedToTerms2(!agreedToTerms2);
                          setTermsErrors((prev) => ({ ...prev, agreedToTerms2: false }));
                        }}
                        className="mr-2"
                      />
                      {pageData[0].agreements.list[1].agreement}
                    </label>
                  </div>
                  <div className="mb-4">
                    <h4 className="text-white text-base mb-2">{pageData[0].agreements.list[2].heading}</h4>
                    <label className={`block text-sm ${termsErrors.agreedToTerms3 ? "text-red-500" : "text-white"}`}>
                      <input
                        type="checkbox"
                        checked={agreedToTerms3}
                        onChange={() => {
                          setAgreedToTerms3(!agreedToTerms3);
                          setTermsErrors((prev) => ({ ...prev, agreedToTerms3: false }));
                        }}
                        className="mr-2"
                      />
                      {pageData[0].agreements.list[2].agreement}
                    </label>
                  </div>
                  <div className="mb-2">
                    <PortableText value={pageData[0].agreements.additionalInfo} />
                  </div>
                  <div className="text-right">
                    <p className="text-sm mb-4">
                      <span className="">Dated: </span>
                      {currentDate}
                    </p>
                  </div>
                  <button onClick={handleUpload} disabled={loading} className={`border text-xl w-full bg-[#0997C5] border-[#0997C5] text-white py-1 px-4 rounded-full focus:outline-none transition-all duration-300 ease-in-out ${loading ? "cursor-not-allowed" : "hover:bg-[#38a2d3]"}`}>
                    {loading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <span>Uploading</span>
                        <div className="w-5 h-5 border-4 border-t-transparent border-white border-solid rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data?.jobs?.map((job: any, index: number) => (
            <div key={index} onClick={() => handleClick(job?.name)} className="bg-white/15 transition duration-100 ease-in-out transform hover:bg-white/30 hover:delay-200 cursor-pointer rounded-md p-8 shadow-2xl">
              <h2 className="text-2xl">{job?.name}</h2>
              <p className="text-lg text-[#0997C5] mt-2">{job?.field}</p>
              <div className="flex mt-6">
                <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 22.7199L9.84133 21.7719C10.796 20.6786 11.6547 19.6413 12.4187 18.6546L13.0493 17.8226C15.6827 14.2746 17 11.4586 17 9.37725C17 4.93459 13.4187 1.33325 9 1.33325C4.58133 1.33325 1 4.93459 1 9.37725C1 11.4586 2.31733 14.2746 4.95067 17.8226L5.58133 18.6546C6.67125 20.0511 7.81156 21.4062 9 22.7199Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.00008 12.6667C10.841 12.6667 12.3334 11.1743 12.3334 9.33333C12.3334 7.49238 10.841 6 9.00008 6C7.15913 6 5.66675 7.49238 5.66675 9.33333C5.66675 11.1743 7.15913 12.6667 9.00008 12.6667Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-xl ml-2">{job?.location}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpenPosition;
