"use client";
import React, { useState, useEffect, useRef } from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
import { PortableText } from "@portabletext/react";

type Props = {};

const Technologies = ({ data }: { data: any }) => {
  const [hoveredTech, setHoveredTech] = useState<{
    name: string;
    description: any;
    x: number;
    y: number;
    source: "mouse" | "touch";
  } | null>(null);

  const hoveredTechRef = useRef(hoveredTech);

  useEffect(() => {
    hoveredTechRef.current = hoveredTech;
  }, [hoveredTech]);

  const handleMouseEnter = (tech: any, event: React.MouseEvent) => {
    setHoveredTech({
      name: tech.name,
      description: tech.description || tech.name,
      x: event.clientX,
      y: event.clientY - 20,
      source: "mouse",
    });
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (hoveredTech && hoveredTech.source === "mouse") {
      setHoveredTech(
        (prev) =>
          prev && {
            ...prev,
            x: event.clientX,
            y: event.clientY - 20,
          }
      );
    }
  };

  const handleMouseLeave = () => {
    setHoveredTech(null);
  };

  const handleTouchStart = (tech: any, event: React.TouchEvent) => {
    const touch = event.touches[0];
    setHoveredTech({
      name: tech.name,
      description: tech.description || tech.name,
      x: touch.clientX,
      y: touch.clientY - 20,
      source: "touch",
    });
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (hoveredTech && hoveredTech.source === "touch") {
      const touch = event.touches[0];
      setHoveredTech(
        (prev) =>
          prev && {
            ...prev,
            x: touch.clientX,
            y: touch.clientY - 20,
          }
      );
    }
  };

  // const handleTouchEnd = () => {
  //   setHoveredTech(null);
  // };
  useEffect(() => {
    const handleScroll = () => {
      if (hoveredTechRef.current) {
        setHoveredTech(null);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (hoveredTech && hoveredTech.source === "touch") {
      const timeout = setTimeout(() => {
        setHoveredTech(null);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [hoveredTech]);

  return (
    <section className="technologies bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32 overflow-visible relative">
      <h1 id="technologies" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
        Technologies We Use
      </h1>
      {data &&
        data?.techStack?.map((technology: any, index: any) => (
          <div key={index} className="slider relative overflow-visible">
            <div className="flex items-center justify-center">
              <div className="border-t border-gray-500 flex-grow"></div>
              <span className="mx-4 text-white text-lg font-medium">{technology?.technologyName}</span>
              <div className="border-t border-gray-500 flex-grow"></div>
            </div>
            <div className="flex justify-center">
              <Marquee pauseOnHover speed={30} direction={index % 2 !== 0 ? "left" : "right"}>
                {technology.tech?.map((tech: any, index: any) => (
                  <div key={index} className="group relative py-10" onMouseEnter={(e) => handleMouseEnter(tech, e)} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onTouchStart={(e) => handleTouchStart(tech, e)} onTouchMove={handleTouchMove}>
                    <div className="bg-gray-800 p-4 mx-4 lg:mx-8 rounded-full">
                      <Image src={tech?.imageUrl} alt={tech?.name} width={100} height={100} className="h-12 w-12 z-10" />
                    </div>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        ))}

      {hoveredTech && (
        <div
          style={{
            position: "fixed",
            top: hoveredTech.y,
            left: Math.min(Math.max(hoveredTech.x, 10), window.innerWidth - 10),
            transform: hoveredTech.x < 150 ? "translate(0, -100%)" : hoveredTech.x > window.innerWidth - 150 ? "translate(-100%, -100%)" : "translate(-50%, -100%)",
            zIndex: 9999,
            width: "fit-content",
            maxWidth: "250px",
            minWidth: "250px",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
          className="bg-gray-800 text-white text-xs font-medium px-2 py-3 rounded-md shadow-lg text-center border border-gray-500"
        >
          <PortableText value={hoveredTech.description} />
          <div className={`absolute w-0 h-0 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent border-t-[5px] border-t-gray-500 bottom-[-5px] ${hoveredTech?.x < 150 ? "left-[10px] transform translate-x-0" : hoveredTech?.x > window.innerWidth - 150 ? "right-[10px] transform translate-x-0" : "left-1/2 transform -translate-x-1/2"}`}></div>
        </div>
      )}
    </section>
  );
};

export default Technologies;
