"use client";
import React from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
import client from "@/client";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Link from "next/link";
import axios from "axios";
type Props = {};
const ContactUs = ({ data, social }: { data: any; social: any }) => {
  const [submitted, setSubmitted] = useState(false);
  const [isdisabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const notify = () => toast.success("Thank you for Contacting us !");
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const { email, name, message } = formData;
    event.preventDefault();
    const doc = {
      _id: Math.random().toString(36).substr(2, 8),
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };
    setDisabled(true);
    try {
      await client.createIfNotExists(doc);
      setFormData({ email: "", name: "", message: "" });
      setSubmitted(true);
      toast.success("Thank you for Contacting us !"); // Displays a success message
      try {
        const response = await axios.post("/api/send-contact-email", {
          name,
          email,
          message,
        });
        if (response.status === 200) {
        } else {
          throw new Error("Email sending failed");
        }
        setDisabled(false);
      } catch (emailError) {
        setDisabled(false);
        console.error("Error sending email:", emailError);
        toast.error("Error sending email.");
      }
    } catch (sanityError) {
      setDisabled(false);
      console.error("Error submitting to Sanity:", sanityError);
      toast.error("Submission Error: Could not save contact.");
    }
  };
  return (
    <section className="contact  bg-[#0F1929] text-white   px-5 py-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
      <h1 id={data?.sectionId} className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12"></h1>
      <div className="grid grid-cols-1  md:grid-cols-2">
        <div className="left-div">
          <h1 className="font-medium text-4xl sm:text-5xl lg:text-4xl xl:text-6xl tracking-tight mt-3">{data?.title}</h1>
          <p className="font-normal text-base xl:text-lg my-8 w-full md:w-10/12">{data?.description}</p>
          <ul className="">
            {data?.features?.map((feature: any) => (
              <li className="flex mt-5" key={feature?._key}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_141_1332)">
                    <path d="M24.6458 13.0001C24.6458 15.3034 23.9628 17.555 22.6831 19.4702C21.4035 21.3853 19.5846 22.878 17.4566 23.7594C15.3286 24.6409 12.987 24.8715 10.728 24.4221C8.4689 23.9728 6.39382 22.8636 4.76512 21.2349C3.13642 19.6062 2.02726 17.5311 1.5779 15.2721C1.12855 13.013 1.35917 10.6714 2.24062 8.54341C3.12206 6.41542 4.61474 4.59659 6.52989 3.31693C8.44503 2.03726 10.6966 1.35425 13 1.35425M21.4034 4.59666L12.2898 17.0019L6.40896 12.0787" stroke="#0997C5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_141_1332">
                      <rect width="26" height="26" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="ml-4">{feature?.title}</p>
              </li>
            ))}
          </ul>
          <div className=" mt-10 flex">
            {social[0]?.list?.map((l: any) => (
              <div key={l?._key} className="mx-2">
                <div className="border border-transparent hover:border-white/60 active:bg-white/10 rounded-md p-1">
                  <a target={"_blank"} rel={"noreferrer"} href={`https://${l?.link}`}>
                    <Image src={l?.logoUrl} width={25} height={25} alt="logo" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-div mt-12 sm:mt-0">
          <div className="w-full px-4 py-4 xl:px-8 sm:py-8 custom-gradient rounded-lg shadow-lg">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <input type="text" placeholder="Name" name="name" value={formData.name} onChange={handleInputChange} className="w-full pl-6 py-3 bg-gray-700 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-blue-500" required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} className="w-full pl-6 py-3 bg-gray-700 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-blue-500" required />
              </div>
              <div className="mb-4">
                <textarea placeholder="Message" name="message" value={formData.message} onChange={handleInputChange} className="w-full pl-6 py-3 bg-gray-700 rounded-2xl text-white focus:outline-none focus:ring-2 focus:ring-blue-500 h-40" required></textarea>
              </div>
              <button disabled={isdisabled} type="submit" className="w-full pl-6 py-3 bg-blue-500 rounded-full text-white hover:bg-blue-600 active:bg-blue-700 active:scale-95 transition transform duration-150 ease-in-out">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1  md:grid-cols-3 gap-5 mt-12">
        {/* {data?.cards?.map((card: any) => ( */}
        <div className="custom-gradient rounded-lg  py-5 px-8 md:px-2 lg:px-8">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full border border-gray-500 cursor-pointer hover:bg-[#ffffff]/15">
            <Link href={`mailTo:${data?.cards[0]?.subTitle}`} aria-label="Send an email to Assiduous Solutions support">
              <Image width={35} height={35} src={data?.cards[0]?.imageUrl} alt="" />
            </Link>
          </div>
          <h2 className="text-2xl pt-4">{data?.cards[0]?.title}</h2>
          <Link href={`mailTo:${data?.cards[0]?.subTitle}`} aria-label="Send an email to Assiduous Solutions support">
            <p className="text-xl pt-2 hover: hover:text-[#ffffff]/15 cursor-pointer">{data?.cards[0]?.subTitle}</p>
          </Link>
        </div>
        <div className="custom-gradient rounded-lg  py-5 px-8 md:px-2 lg:px-8">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full border border-gray-500 cursor-pointer hover:bg-[#ffffff]/15">
            <Link href={`tel:${data?.cards[1]?.subTitle}`} aria-label="Call to Assiduous Solutions support">
              <Image width={35} height={35} src={data?.cards[1]?.imageUrl} alt="" />
            </Link>
          </div>
          <h2 className="text-2xl pt-4">{data?.cards[1]?.title}</h2>
          <Link href={`tel:${data?.cards[1]?.subTitle}`} aria-label="Call to Assiduous Solutions support">
            <p className="text-xl pt-2 hover: hover:text-[#ffffff]/15 cursor-pointer">{data?.cards[1]?.subTitle}</p>
          </Link>
        </div>
        <div className="custom-gradient rounded-lg  py-5 px-8 md:px-2 lg:px-8">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full border border-gray-500 cursor-pointer hover:bg-[#ffffff]/15">
            <Link href="/#location" aria-label="Assiduous Solutions Map location">
              <Image width={35} height={35} src={data?.cards[2]?.imageUrl} alt="" />
            </Link>
          </div>
          <h2 className="text-2xl pt-4">{data?.cards[2]?.title}</h2>
          <Link href="/#location" aria-label="Assiduous Solutions Map location">
            {" "}
            <p className="text-xl pt-2 hover: hover:text-[#ffffff]/15 cursor-pointer">{data?.cards[2]?.subTitle}</p>
          </Link>
        </div>
        {/* ))} */}
      </div>
    </section>
  );
};

export default ContactUs;
