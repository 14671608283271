"use client";
import React, { useState } from "react";
import Image from "next/image";
import Header from "../header";
import { motion } from "framer-motion";
import styles from "./Banner.module.css"; // Assuming you put the CSS in this file
import Link from "next/link";
const Banner = ({ data, header, services, industries }: { data: any; header: any; services: any; industries: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const applyEffect = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className={` bg-[#0F1929] modal-open overflow-hidden shadow-inner`}>
        <div className="">
          <Header header={header} applyEffect={applyEffect} data={services} industries={industries} />
          <div className={` grid sm:grid-cols-1 md:grid-cols-2 gap-4 ${isOpen ? "transition-all duration-300 opacity-10" : ""}`}>
            {/* <React.Fragment className={` ${isOpen ? "opacity-10" : ""}`}> */}
            <div className={`hidden sm:block md:top-32 md:left-12 lg:top-32 lg:left-20 2xl:top-60 2xl:left-28 absolute bg-[url('/image.png')] w-44 h-40 `}></div>
            <div className={` top-32 left-3 sm:hidden sm:top-32 sm:left-20 absolute bg-[url('/image.png')] w-44 h-40 `}></div>

            <div className={`ml-5 mt-48 sm:ml-16 lg:ml-32 2xl:mt-80 w-10/12 sm:10/12 lg:w-8/12  `}>
              <h1 className="hidden sm:block text-4xl xl:text-5xl 2xl:text-6xl font-bold text-white  ">
                {data?.title}
                <span className="text-[#0997C5]  "> {data?.highlightedTitle}</span>
              </h1>
              <span className="sm:hidden text-4xl font-bold">
                <h1 className="text-white">{data?.title}</h1>
                <h1 className="text-[#0997C5]"> {data?.highlightedTitle}</h1>
              </span>
              <p className="text-lg text-white mt-4">{data?.description}</p>
              <div className="mt-6 flex space-x-4">
                <Link href={data?.button?.target ? data?.button?.target : "#"}>
                  <button className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-6 py-4 rounded-full text-[18px]">{data?.button?.title}</button>
                </Link>
                <Link href={data?.button2?.target ? data?.button2?.target : "#"}>
                  <button className="border border-white text-white px-6 py-4 rounded-full hover:text-[#0997C5] hover:border-[#0997C5]  active:bg-[#ffffff28] text-[18px]">{data?.button2?.title}</button>
                </Link>
              </div>
            </div>

            <div className={`relative shadow-inner`}>
              <div className="relative mx-auto bg-[#0F1929] mt-12 sm:mt-0">
                <div className="relative h-[520px] sm:h-screen flex items-center justify-center ">
                  <div className="w-3/4 h-full overflow-hidden relative">
                    <div className="absolute bottom-10 h-[120%] w-full">
                      <div className="grid grid-cols-3 gap-4 sm:gap-0 fade-in-image ">
                        <div className="flex flex-col mt-52  ">
                          <div
                            className="fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem] mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl1})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem]  bg-cover bg-center mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl2})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>
                        </div>
                        <div className="flex flex-col">
                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem] bg-cover bg-center"
                            style={{
                              backgroundImage: `url(${data?.imageUrl3})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem] bg-cover bg-center mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl4})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem] bg-cover bg-center mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl5})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>
                        </div>
                        <div className="flex flex-col mt-52">
                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w-[11rem] bg-cover bg-center mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl6})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          <div
                            className=" fade-in-image rounded-full overflow-hidden h-[16rem] w-[5rem] sm:h-[20rem] sm:w-[5rem] lg:h-[20rem] lg:w-[7rem] xl:h-[20rem] xl:w-[8.7rem] 2xl:h-[30rem] 2xl:w/[11rem] bg-cover bg-center mt-5"
                            style={{
                              backgroundImage: `url(${data?.imageUrl7})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </React.Fragment> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
