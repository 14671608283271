"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay, Navigation, HashNavigation } from "swiper/modules";

const Industries = ({ data }: { data: any }) => {
  return (
    <section className="industries bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
      <h1 id={data?.sectionId} className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
        {data?.title}
      </h1>
      <p className="font-normal text-base md:text-lg mt-5">{data?.description}</p>

      <div className="slider mt-12">
        {data && (
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={true} // Ensure this is set to true
            autoplay={{ delay: 1500, disableOnInteraction: true }}
            // hashNavigation={{ watchState: true }}
            breakpoints={{
              500: { slidesPerView: 1, spaceBetween: 0 },
              640: { slidesPerView: 2, spaceBetween: 0 },
              768: { slidesPerView: 2, spaceBetween: 0 },
              1024: { slidesPerView: 4, spaceBetween: 0 },
              1400: { slidesPerView: 5, spaceBetween: 0 },
            }}
            modules={[Autoplay, Pagination, Navigation]} // Ensure Navigation is included here
            className="mySwiper"
          >
            {data?.industries?.map((industry: any, index: any) => (
              <SwiperSlide key={index}>
                <div
                  className="relative mx-2 h-[430px] bg-cover bg-center m-2 rounded-lg"
                  style={{
                    backgroundImage: `url(${industry?.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/60">
                    <div className="pt-72">
                      <div className="p-4">
                        <h2 className="text-white text-xl font-semibold">{industry?.name}</h2>
                        <p className="text-gray-300 mt-2">{industry?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default Industries;
