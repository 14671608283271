import(/* webpackMode: "eager" */ "/vercel/path0/components/banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/contact-us/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/industries/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/maps/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/open-position/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/our-approach/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/our-services/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/scrollToTop/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/technologies/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
