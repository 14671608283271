"use client";

import React, { useEffect, useState } from "react";
import useStore from "../../store/useStore";
const OurServices = ({ data }: { data: any }) => {
  const { currentService, updateCurrentService } = useStore();
  const [activeSection, setActiveSection] = useState<any>(data.services[0]);
  useEffect(() => {
    if (Object.keys(currentService).length === 0) {
      setActiveSection(data.services[0]);
    } else {
      setActiveSection(currentService);
    }
    if (!currentService === null) setActiveSection(currentService);
  }, [currentService, data.services]);
  const handleToggleSection = (section: any = 1) => {
    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  return (
    <>
      <section className="services bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
        <h1 id="services" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
          What We Do?
        </h1>
        <div className="flex items-center justify-center text-white md:bg-white/15 rounded ">
          <div className=" py-3 rounded-lg overflow-hidden shadow-lg w-full max-w-6xl 2xl:max-w-none">
            <div className="rounded-lg overflow-hidden  w-full max-w-6xl 2xl:max-w-none">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-3/12 p-0">
                  {/* Mobile Dropdown */}
                  <div className="md:hidden mb-4  sm:mt-0">
                    {data?.services?.map((section: any, index: any) => (
                      <div key={index} className="mb-2">
                        <button onClick={() => setActiveSection(section)} className={`block w-full text-left px-6 py-4 rounded-xl bg-[#0997C5] text-white focus:outline-none ${activeSection.name === section.name ? "bg-[#0997C5] text-white" : "bg-gray-700 text-gray-300"}`}>
                          {section?.name}
                          <span className="float-right">{/* {activeSection?.id === section.id ? <ChevronUpIcon className="w-5 h-5 inline-block" /> : <ChevronDownIcon className="w-5 h-5 inline-block" />} */}</span>
                        </button>
                        {activeSection.name === section.name && (
                          <div className="mt-2 px-6 py-4 rounded-xl bg-gray-700 text-white">
                            <p className="mt-4 mb-4">{section?.descriptionDetail}</p>
                            <ul className="grid grid-cols-2 gap-2 mt-4">
                              {section?.list?.map((item: any, index: number) => (
                                <li key={index} className="flex items-center">
                                  {/* Icon and Text in the same container */}
                                  <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mr-2"></span>
                                  <span>{item?.title}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {/* Desktop Sidebar */}
                  <div className="hidden md:block overflow-y-auto services-custom-max-h services-custom-min-h custom-scrollbar">
                    {data?.services.map((section: any, index: any) => (
                      <div className="flex w-full my-6" key={index}>
                        <div className=" mx-3 w-full">
                          <button onClick={() => setActiveSection(section)} className={`block w-full text-[1.20rem] text-left px-2 py-2 rounded-md ${activeSection.name === section.name ? " bg-[#0997C5] text-white" : "text-gray-300"} focus:outline-none`}>
                            <span className={`inline-block ${activeSection.name === section.name && "border-r-6 border-white pr-2"}`}>{section?.name}</span>
                          </button>
                        </div>
                        <div className={`${activeSection === section ? "bg-[#0997C5] w-[5px] rounded-l" : ""}`}></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border border-[#FFFFF]/40 hidden md:block"></div>
                <div className="md:w-9/12 g-[#ffffff]/30 px-12 py-10 hidden md:block overflow-y-auto services-custom-max-h services-custom-min-h   custom-scrollbar">
                  {activeSection && (
                    <>
                      <h2 className="text-3xl font-bold underline">{activeSection?.name}</h2>
                      <p className="my-6">{activeSection?.descriptionDetail}</p>
                      <div className="grid grid-cols-2 gap-2">
                        {activeSection?.list?.map((item: any, index: any) => (
                          <div key={index} className="flex items-center mt-4">
                            <span className="inline-block hidden md:block w-3.5 h-3.5 bg-cyan-500 rounded-full mr-2"></span>
                            <span>{item?.title}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
