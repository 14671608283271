"use client";
import React from "react";
import client from "@/client";
import Image from "next/image";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Link from "next/link";
import useStore from "../../store/useStore";
import axios from "axios";
type Props = {};
const Footer = ({ social, services, industries, header }: { social: any; services: any; industries: any; header: any }) => {
  const { currentService, updateCurrentService } = useStore();
  const [submitted, setSubmitted] = useState(false);
  const [isdisabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const notify = () => toast.success("Thank you for Contacting us !");
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: any) => {
    setDisabled(true);
    const { email } = formData;
    event.preventDefault();
    const doc = {
      _id: Math.random().toString(36).substr(2, 8),
      _type: "newsletter",
      email: email,
    };

    try {
      await client.createIfNotExists(doc);
      setFormData({ email: "" });
      setSubmitted(true);
      toast.success("Newsletter Subscribed !"); // Displays a success message
      try {
        const res = await axios.post("/api/newsletter-subscription", { email });
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || "An error occurred";
      }
      try {
        const response = await axios.post("/api/send-newsletter-email", {
          email,
        });
        if (response.status === 200) {
          console.log(response, "email sent successfully");
        } else {
          throw new Error("Email sending failed");
        }
      } catch (emailError) {
        console.error("Error sending email:", emailError);
        toast.error("Error sending email.");
      }
      setDisabled(false);
    } catch (sanityError) {
      setDisabled(false);
      console.error("Error submitting to Sanity:", sanityError);
      toast.error("Submission Error: Could not save newsletter.");
    }
  };
  const handleRedirect = (service: any) => {
    updateCurrentService(service);

    return 0;
  };
  return (
    <footer className=" bg-[#0F1929] text-white py-8 relative overflow-hidden">
      <div className="container mx-auto px-4 md:px-16 relative">
        <div className="flex flex-row md:flex-row md:justify-start mb-20 relative">
          <div>
            <img src={header[0]?.logoUrl} alt="assiduous" className="w-100 h-[40px] mb-2 md:mb-0" />
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
          <div className="col-span-1 md:col-span-1">
            <h3 className="text-[#0997C5] text-base md:text-lg font-semibold">Industries</h3>
            <ul className="mt-2 md:mt-4 space-y-1 md:space-y-2 text-sm md:text-base">
              {industries?.industries?.map((industry: any, index: any) => (
                <li key={index} className="text-white py-1 hover:text-[#0997C5] hover:cursor-pointer">
                  <Link href="#industries">{industry?.name}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-1 md:col-span-1">
            <h3 className="text-[#0997C5] text-base md:text-lg font-semibold">Services</h3>
            <ul className="mt-2 md:mt-4 space-y-1 md:space-y-2 text-sm md:text-base">
              {services?.services?.map((service: any, index: any) => (
                <li className="text-white hover:text-[#0997C5] py-1 hover:cursor-pointer" key={index}>
                  <Link onClick={() => handleRedirect(service)} href="#services">
                    {service.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-2 md:col-span-1">
            <h3 className="text-[#0997C5] text-base md:text-lg font-semibold">About Us</h3>
            <ul className="mt-2 md:mt-4 space-y-1 md:space-y-2 text-sm md:text-base">
              <li className="text-white hover:text-[#0997C5] py-1 hover:cursor-pointer">
                <Link href="#technologies">Our Technologies</Link>
              </li>
              <li className="text-white hover:text-[#0997C5] py-1 hover:cursor-pointer">
                <Link href="#our-approach">Methodologies</Link>
              </li>
              <li className="text-white hover:text-[#0997C5] py-1 hover:cursor-pointer">
                <Link href="#careers">Apply For A Role</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h3 className="text-[#0997C5] text-base md:text-lg font-semibold">Subscribe To Our Newsletter</h3>
            <form onSubmit={handleSubmit}>
              <div className="mt-2 md:mt-4 flex flex-col space-y-5">
                <input type="email" name="email" value={formData.email} onChange={handleInputChange} required placeholder=" Enter your email" className="p-3 text-black rounded-full w-full" />
                <button className="bg-[#0997C5] text-white rounded-full hover:bg-blue-600 active:bg-blue-700 active:scale-95 transition transform duration-150 ease-in-out w-full h-12" disabled={isdisabled}>
                  Subscribe
                </button>
              </div>
            </form>

            <h3 className="text-[#0997C5] text-base md:text-lg font-semibold mt-4 md:mt-8">Follow Us On</h3>
            <div className=" mt-3 flex">
              {social[0]?.list?.map((l: any) => (
                <div key={l?._key} className="mx-2">
                  <div className="border border-transparent hover:border-white/60 active:bg-white/10 rounded-md p-1">
                    <a target={"_blank"} rel={"noreferrer"} href={`https://${l?.link}`}>
                      <Image src={l?.logoUrl} width={25} height={25} alt="logo" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="mt-2 md:mt-4 flex  md:justify-start space-x-2 md:space-x-4 text-white">
            <a href="#">
              <FaLinkedin size={24} />
            </a>
            <a href="#">
              <FaInstagram size={24} />
            </a>
            <a href="#">
              <FaTwitter size={24} />
            </a>
            <a href="#">
              <FaFacebook size={24} />
            </a>
            <a href="#">
              <FaYoutube size={24} />
            </a>
          </div> */}
          </div>
        </div>
      </div>
      <div className="w-full mt-8">{/* <img src="/Line 3.png" alt="Line" className="w-full" /> */}</div>
      <hr className="h-px bg-[#FFFFFF]/15 border-0 " />
      <div className="container mx-auto text-center mt-8 text-gray-500 text-sm">
        <p>Copyright © 2024 Assiduous | All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
