"use client";
import React from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
// import { motion } from "framer-motion";
import Link from "next/link";
type Props = {};
const OurApproach = ({ data }: { data: any }) => {
  return (
    // <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }}>
    <section className="approach bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
      <h1 id="our-approach" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12"></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
        <div className="my-auto">
          <Image src={data?.imageUrl} width={500} height={500} alt="dsd"></Image>
        </div>
        <div className="w-12/12">
          <h1 className="font-medium text-4xl sm:text-4xl  xl:text-6xl tracking-tight mt-10 sm:mt-0">{data?.title}</h1>
          <p className="text-base lg:text-lg my-10">{data?.description}</p>
          <Link href={data?.button?.target ? `/${data?.button?.target}` : "#"}>
            <button className="border border-white text-white px-6 py-3 rounded-3xl  hover:text-[#0997C5] hover:border-[#0997C5]  active:bg-[#ffffff28] ">{data?.button?.title}</button>
          </Link>
        </div>
      </div>
    </section>
    // </motion.div>
  );
};

export default OurApproach;
