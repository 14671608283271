"use client";
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
import { motion } from "framer-motion";
import { Map, Marker, ZoomControl } from "pigeon-maps";
type Props = {};
const Maps = ({ data }: { data: any }) => {
  const [touch, setTouch] = useState(false);
  return (
    <section className="maps rounded bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32" onClick={() => setTouch(true)}>
      <h1 id="location" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
        {data?.title}
      </h1>

      <Map height={500} defaultCenter={[47.4116, 28.3699]} mouseEvents={true} touchEvents={touch} defaultZoom={3} metaWheelZoom={true}>
        {data?.locations?.map((location: any, index: any) => (
          <Marker width={50} key={index} anchor={[Number(location?.lat), Number(location?.long)]}>
            <div className="text-center ">
              <b className="text-gray-700/80 text-xs text-center mx-auto">{location?.countryName}</b>

              <svg width="61" height="58.62068965517241" viewBox="0 0 61 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g style={{ pointerEvents: "auto" }}>
                  <path d="M52 31.5C52 36.8395 49.18 42.314 45.0107 47.6094C40.8672 52.872 35.619 57.678 31.1763 61.6922C30.7916 62.0398 30.2084 62.0398 29.8237 61.6922C25.381 57.678 20.1328 52.872 15.9893 47.6094C11.82 42.314 9 36.8395 9 31.5C9 18.5709 18.6801 9 30.5 9C42.3199 9 52 18.5709 52 31.5Z" fill="hsl(220deg 39% 70%)" stroke="white" strokeWidth="4"></path>
                  <circle cx="30.5" cy="30.5" r="8.5" fill="white" opacity="0.6"></circle>
                </g>
              </svg>
            </div>
          </Marker>
        ))}
        <ZoomControl />
      </Map>
    </section>
  );
};

export default Maps;
